




















import Logo from "@/components/Logo.vue";
import { defineComponent, ref, onMounted, computed } from "@vue/composition-api";
import Button from "@/components/Buttons/Button.vue";
import { IS_LOGGED_IN } from "@/store/auth";
import store from "@/store";

export default defineComponent({
  name: "Login",
  components: { Logo, Button },
  setup(props, { root }) {
    const message = ref<string | (string | null)[]>("");
    const isLoggedIn = computed(() => store.getters[IS_LOGGED_IN]);

    onMounted(() => {
      message.value = root.$route.query.errorMessage;
    });

    return {
      message,
      isLoggedIn
    };
  }
});
