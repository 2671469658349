var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "bg-btYellow h-screen flex justify-center items-center flex-col"
    },
    [
      _c(
        "router-link",
        {
          staticClass: "mb-8 w-3/4 max-w-lg flex justify-center",
          attrs: { to: { name: "Dashboard" } }
        },
        [_c("Logo")],
        1
      ),
      _c("div", { staticClass: "flex justify-center flex-col" }, [
        _c("div", { staticClass: "text-center mb-4" }, [
          _vm._v("Ups. Something went wrong")
        ]),
        _vm.message
          ? _c("div", { staticClass: "text-red-500 font-semibold" }, [
              _vm._v("Error: " + _vm._s(_vm.message))
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "flex justify-center" },
          [
            _vm.isLoggedIn
              ? _c(
                  "router-link",
                  { attrs: { to: { name: "Dashboard" } } },
                  [
                    _c(
                      "Button",
                      { staticClass: "mt-8", attrs: { variant: "tertiary" } },
                      [_vm._v(" Dashboard ")]
                    )
                  ],
                  1
                )
              : _c(
                  "router-link",
                  { attrs: { to: { name: "Login" } } },
                  [
                    _c(
                      "Button",
                      { staticClass: "mt-8", attrs: { variant: "tertiary" } },
                      [_vm._v(" Login ")]
                    )
                  ],
                  1
                )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }